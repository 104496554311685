import React from "react";
import { Link, NavLink } from "react-router-dom";

import MainFooter from "./MainFooter";
import Brandlogo from "../../../assets/images/shared/brand-logo-footer.svg";
import "./Footer-Navigation.scss";
// import NavLinks from "../Navigation/NavLinks";

const FooterNavigation = () => {
  return (
    <MainFooter>
      <div className="container">
        <div className="footer-logo">
          <Link to="/" className="header-brand-logo">
            <img src={Brandlogo} alt="Interactive eq logo" />
          </Link>
          <p className="text-body text-secondary-l copyright-title">
            Interactive EQ Copyright © 2024
          </p>
        </div>
        <nav className="main-navigation__header-nav">
          <ul>
            <li className="text-body">
              <NavLink className="text-secondary-l" to="/product" exact="true">
                Product
              </NavLink>
            </li>
            <li className="text-body">
              <NavLink
                className="text-secondary-l"
                to="/recruiters"
                exact="true"
              >
                Recruiters
              </NavLink>
            </li>

            <li className="text-body">
              <NavLink
                className="text-secondary-l"
                to="/candidates"
                exact="true"
              >
                Candidates
              </NavLink>
            </li>
            <li className="text-body">
              <NavLink
                className="text-secondary-l"
                to="/people-resources"
                exact="true"
              >
                People resources
              </NavLink>
            </li>
            <li className="text-body">
              <NavLink
                className="text-secondary-l"
                to="/solutions"
                exact="true"
              >
                SaaS
              </NavLink>
            </li>
            <li className="text-body">
              <NavLink
                className="text-secondary-l"
                to="/auto-dealer"
                exact="true"
              >
                Automotive retail
              </NavLink>
            </li>
          </ul>{" "}
          <ul>
            <li className="text-body">
              <NavLink className="text-secondary-l" to="/company" exact="true">
                Origin story
              </NavLink>
            </li>
            <li className="text-body">
              <NavLink className="text-secondary-l" to="/faq" exact="true">
                FAQ
              </NavLink>
            </li>

            <li className="text-body">
              <NavLink
                className="text-secondary-l"
                to="/compliance"
                exact="true"
              >
                Compliance
              </NavLink>
            </li>
          </ul>
          <ul>
            <li className="text-body">
              <NavLink
                className="text-secondary-l"
                to="/contact-us"
                exact="true"
              >
                Contact us
              </NavLink>
            </li>

            <li className="text-body">
              <NavLink
                className="text-secondary-l "
                to="/terms-of-use"
                exact="true"
              >
                Terms Of Use
              </NavLink>
            </li>
            <li className="text-body">
              <NavLink
                className="text-secondary-l "
                to="/privacy-policy"
                exact="true"
              >
                Privacy Policy
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </MainFooter>
  );
};

export default FooterNavigation;
